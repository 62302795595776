import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">

            </header>
            <body className="App-body">
                <h1>test</h1>
                <p>test</p>
            </body>
            <footer className="App-footer">

            </footer>
        </div>
    );
}

export default App;
